.itemIcon-selected {
  width: 100%;
  height: auto;
  border: 2px solid $primary;
  outline: 4px solid $primary-200;
}

.item-selected-icon {
  position: absolute;
  right: $spacer * 0.5;
  padding: $spacer * 0.25;
  background-color: $body-bg;
  border: 2px solid $primary;

  svg {
    display: block;
    width: 1rem;
    height: 1rem;
  }
}

.itemIcon {
  width: 100%;
  height: auto;
  border: 2px solid;
  cursor: pointer;
  @include transition($btn-transition);

  &:hover,
  &:focus,
  &:active {
    border-color: $primary;
  }
}
