@import "helpers";
@import "buttons";
@import "punchy";
@import "menu";
@import "outlets";
/* .Tabs {
  width: 80%;
  height: 100vw;
  min-height: 400px;
  background-color: #282c34;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #E8F0F2;
  border-radius: 2rem;
  @media (max-width: 769px) {
   padding: 2rem 0;
  }
 }

 ul.nav {
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39A2DB;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 20%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;


}
ul.nav li:nth-child(5) {
  border-radius: 0;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
ul.nav li:nth-child(1) {
  border-radius: 0;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background: #39A2DB;
} */
.punchy-history {
  text-decoration: none;
  list-style: none;
}
.punchy-history li {
  cursor: pointer;
  border: 1px solid blue;
}
.punchy-history button {
  float: right;
}
.punchy-canvas {
  margin-top: 300px;
  border: 1px solid red;
  display: none;
}
.punchy-thumb {
  border: 1px solid black;
  width: 150px;
  height: 150px;
  cursor: pointer;
}
