.customize-options {
  border: 2px solid;
}

.customize-title {
  border-bottom: 2px solid;
}

.tab-container {
  display: flex;
}

.tab {
  border-right: 2px solid;
  display: flex;
  margin-bottom: -2px; // To offset the bottom double-borders
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-up(xl) {
    min-width: 9rem;
  }
}

/* Style the buttons inside the tab */
.tab > button {
  background: none;
	color: inherit;
  border: none;
	padding: ($spacer * 0.5) ($spacer);
	font-family: $font-family-monospace;
	cursor: pointer;
	outline: inherit;
	border-bottom: 2px solid;
  @include transition($btn-transition);

  &:hover,
  &:focus,
  &:active {
    background-color: $tertiary-200;
  }

  &.active {
    background-color: $tertiary-400;
  }
}

.tab > div {
  border-bottom: 2px solid;
}

/* Change background color of buttons on hover */
.tab button:hover {
  // background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  // background-color: #ccc;
}

/* Style the tab content */
.tabcontent {

}

