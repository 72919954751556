.punchy-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.punchy-image {
	display: block;
  position: absolute;
	max-width :100%;
	max-height: 100%;
	top: 50%;
	left: 50%;
	padding: $spacer;
	transform: translate(-50%, -50%);
}