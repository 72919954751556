//////////////////////////////////////////////////
// Site config
//////////////////////////////////////////////////

// Config variables that affect Bootstrap styles.
// Update these variables as needed.
$enable-negative-margins: true;
$enable-shadows: true;

// Custom config variables.
// Update these variables as needed.
$enable-navbar-fixed: false;

//////////////////////////////////////////////////
// Color system
//////////////////////////////////////////////////

// Brand Colours 
// Update these variables as needed.
$primary: #f05a41;
$secondary: #f1a331;
$tertiary: #a9d8b8;
$quaternary: #f3df6b;
$quinary: #5fc5ba;
$senary: #fcf8d0;
$septenary: #434443;

// Contextual Colours
// Update these variables as needed.
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

// Helper Colours
// Update these variables as needed.
$light: #fcfcfc;
$dark: #434443;
$gray: #adb5bd;

// Brand Primary Colour Shades
$primary-100: tint-color($primary, 80%);
$primary-200: tint-color($primary, 60%);
$primary-300: tint-color($primary, 40%);
$primary-400: tint-color($primary, 20%);
$primary-500: $primary;
$primary-600: shade-color($primary, 20%);
$primary-700: shade-color($primary, 40%);
$primary-800: shade-color($primary, 60%);
$primary-900: shade-color($primary, 80%);

// Brand Secondary Colour Shades
$secondary-100: tint-color($secondary, 80%);
$secondary-200: tint-color($secondary, 60%);
$secondary-300: tint-color($secondary, 40%);
$secondary-400: tint-color($secondary, 20%);
$secondary-500: $secondary;
$secondary-600: shade-color($secondary, 20%);
$secondary-700: shade-color($secondary, 40%);
$secondary-800: shade-color($secondary, 60%);
$secondary-900: shade-color($secondary, 80%);

// Brand Tertiary Colour Shades
$tertiary-100: tint-color($tertiary, 80%);
$tertiary-200: tint-color($tertiary, 60%);
$tertiary-300: tint-color($tertiary, 40%);
$tertiary-400: tint-color($tertiary, 20%);
$tertiary-500: $tertiary;
$tertiary-600: shade-color($tertiary, 20%);
$tertiary-700: shade-color($tertiary, 40%);
$tertiary-800: shade-color($tertiary, 60%);
$tertiary-900: shade-color($tertiary, 80%);

// Brand Quaternary Colour Shades
$quaternary-100: tint-color($quaternary, 80%);
$quaternary-200: tint-color($quaternary, 60%);
$quaternary-300: tint-color($quaternary, 40%);
$quaternary-400: tint-color($quaternary, 20%);
$quaternary-500: $quaternary;
$quaternary-600: shade-color($quaternary, 20%);
$quaternary-700: shade-color($quaternary, 40%);
$quaternary-800: shade-color($quaternary, 60%);
$quaternary-900: shade-color($quaternary, 80%);

// Brand Quinary Colour Shades
$quinary-100: tint-color($quinary, 80%);
$quinary-200: tint-color($quinary, 60%);
$quinary-300: tint-color($quinary, 40%);
$quinary-400: tint-color($quinary, 20%);
$quinary-500: $quinary;
$quinary-600: shade-color($quinary, 20%);
$quinary-700: shade-color($quinary, 40%);
$quinary-800: shade-color($quinary, 60%);
$quinary-900: shade-color($quinary, 80%);

// Brand Senary Colour Shades
$senary-100: tint-color($senary, 80%);
$senary-200: tint-color($senary, 60%);
$senary-300: tint-color($senary, 40%);
$senary-400: tint-color($senary, 20%);
$senary-500: $senary;
$senary-600: shade-color($senary, 20%);
$senary-700: shade-color($senary, 40%);
$senary-800: shade-color($senary, 60%);
$senary-900: shade-color($senary, 80%);

// Brand Septenary Colour Shades
$septenary-100: tint-color($septenary, 80%);
$septenary-200: tint-color($septenary, 60%);
$septenary-300: tint-color($septenary, 40%);
$septenary-400: tint-color($septenary, 20%);
$septenary-500: $septenary;
$septenary-600: shade-color($septenary, 20%);
$septenary-700: shade-color($septenary, 40%);
$septenary-800: shade-color($septenary, 60%);
$septenary-900: shade-color($septenary, 80%);

// Contextual Success Colour Shades
$success-100: tint-color($success, 80%);
$success-200: tint-color($success, 60%);
$success-300: tint-color($success, 40%);
$success-400: tint-color($success, 20%);
$success-500: $success;
$success-600: shade-color($success, 20%);
$success-700: shade-color($success, 40%);
$success-800: shade-color($success, 60%);
$success-900: shade-color($success, 80%);

// Contextual Info Colour Shades
$info-100: tint-color($info, 80%);
$info-200: tint-color($info, 60%);
$info-300: tint-color($info, 40%);
$info-400: tint-color($info, 20%);
$info-500: $info;
$info-600: shade-color($info, 20%);
$info-700: shade-color($info, 40%);
$info-800: shade-color($info, 60%);
$info-900: shade-color($info, 80%);

// Contextual Warning Colour Shades
$warning-100: tint-color($warning, 80%);
$warning-200: tint-color($warning, 60%);
$warning-300: tint-color($warning, 40%);
$warning-400: tint-color($warning, 20%);
$warning-500: $warning;
$warning-600: shade-color($warning, 20%);
$warning-700: shade-color($warning, 40%);
$warning-800: shade-color($warning, 60%);
$warning-900: shade-color($warning, 80%);

// Contextual Danger Colour Shades
$danger-100: tint-color($danger, 80%);
$danger-200: tint-color($danger, 60%);
$danger-300: tint-color($danger, 40%);
$danger-400: tint-color($danger, 20%);
$danger-500: $danger;
$danger-600: shade-color($danger, 20%);
$danger-700: shade-color($danger, 40%);
$danger-800: shade-color($danger, 60%);
$danger-900: shade-color($danger, 80%);

// Grays Colour Shades
$gray-100: tint-color($gray, 80%);
$gray-200: tint-color($gray, 60%);
$gray-300: tint-color($gray, 40%);
$gray-400: tint-color($gray, 20%);
$gray-500: $gray;
$gray-600: shade-color($gray, 20%);
$gray-700: shade-color($gray, 40%);
$gray-800: shade-color($gray, 60%);
$gray-900: shade-color($gray, 80%);

// Helper Colours
$white: #fff;
$black: #000;

// Theme Colours
// Helper classes are generated using the colours defined in $theme-colors.
// Add and remove colours as appropriate for the system based on the variables defined above.
$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'success': $success,
	'info': $info,
	'warning': $warning,
	'danger': $danger,
	'dark': $dark,
);

//////////////////////////////////////////////////
// Transitions
//////////////////////////////////////////////////

$transition-duration-base: 175ms;
$transition-duration-long: 300ms;
$transition-bezier: cubic-bezier(0.4, 0, 0.2, 1);

//////////////////////////////////////////////////
// Spacing
//////////////////////////////////////////////////

$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 2,
	5: $spacer * 3,
	6: $spacer * 4,
	7: $spacer * 5
);

//////////////////////////////////////////////////
// Body
//////////////////////////////////////////////////

$body-color: #333;
$body-bg: $senary-100;

//////////////////////////////////////////////////
// Links
//////////////////////////////////////////////////

$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$text-muted: rgba($body-color, 0.4);

//////////////////////////////////////////////////
// Grid breakpoints
//////////////////////////////////////////////////

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);

//////////////////////////////////////////////////
// Grid containers
//////////////////////////////////////////////////

$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

//////////////////////////////////////////////////
// Grid columns
//////////////////////////////////////////////////

$grid-gutter-width: $spacer * 2;

//////////////////////////////////////////////////
// Components
//////////////////////////////////////////////////

$line-height-sm: 1.2;

$border-width: 1px;
$border-color: $gray-400;
$border: $border-width solid $border-color;

$border-radius: 0.25rem;
$border-radius-lg: 0.5rem;
$border-radius-sm: 0.2rem;

$brand-aspect-ratio: math.div(138, 316);

$disabled-opacity: 0.25;

$icon-size: 1.715rem;

//////////////////////////////////////////////////
// Fonts
//////////////////////////////////////////////////

$font-family-base: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$sans-serif-family: $font-family-base;
$font-family-monospace: 'Overpass Mono', monospace;

$font-size-root: 16px;
$font-size-base: 1rem;

$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;

$headings-font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, serif;
$headings-font-weight: 600;
$headings-margin-bottom: 0.75rem;
$headings-line-height: 1.2;

$display-font-sizes: ( 
	1: 3.5rem,
	2: 3rem,
	3: 2.5rem,
	4: 2rem,
	5: 1.5rem,
	6: 1.25rem
);

$display-font-weight: 700;

$display-line-height: 1.1;

$lead-font-size: ($font-size-base * 1.25);
$lead-font-weight: 400;

$max-read-width: 40em;

$small-font-size: 85%;

//////////////////////////////////////////////////
// Tables
//////////////////////////////////////////////////

$table-hover-bg: mix($primary, $gray-100, 2%);

$table-border-width: 1px;

$table-head-cell-padding-y: 0.3rem;

$table-highlight-bg: lighten($primary, 36%);
$table-highlight-bg-hover: darken(white, 4%);
$table-highlight-border: $table-highlight-bg-hover;

$table-icon-padding-y: 0.62rem;
$table-icon-padding-x: 0.75rem;

$table-cell-padding-sm: 0.5rem;

//////////////////////////////////////////////////
// Buttons
//////////////////////////////////////////////////

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$btn-padding-x: 2rem;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1.5rem;
$btn-padding-x-lg: 2.5rem;

$btn-border-radius: 0;

$input-btn-icon-padding: 0.75em;

$btn-font-weight: 700;

$btn-box-shadow: 0;
$btn-hover-box-shadow: 0 3px 12px 0 rgba(#000, 0.3);
$btn-active-box-shadow: 0 1px 2px 0 rgba(#000, 0.3);

$btn-disabled-opacity: 0.25;

//////////////////////////////////////////////////
// Tabs
//////////////////////////////////////////////////

$tab-padding-x: $input-btn-padding-x;
$tab-padding-y: $input-btn-padding-y;

$tab-colour: $link-color;

$tab-hover-color: $link-hover-color;
$tab-hover-bg: rgba($link-color, 0.1);

$tab-active-color: $body-color;
$tab-active-bg: transparent;

//////////////////////////////////////////////////
// Forms
//////////////////////////////////////////////////

$form-group-margin-bottom: 1.75rem;

$input-border-color: $gray-400;

$input-label-font-weight: 700;

$input-btn-focus-color: rgba($primary, .25);
$input-focus-border-color: $primary;

$input-placeholder-color: $gray-400;

$input-btn-line-height: $line-height-base;
$input-btn-border-width: $border-width;

$input-height-border: $input-btn-border-width * 2;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-switch-handle-bg: $primary;
$input-switch-handle-color: #fff;

$custom-control-indicator-bg: transparent;
$custom-control-indicator-border: $input-border-color;

$custom-control-indicator-checked-bg: $primary;

$custom-control-indicator-active-bg: rgba($primary, 0.35);
$custom-control-indicator-active-border: $custom-control-indicator-active-bg;


$tedxtarea-min-height: 10rem;

//////////////////////////////////////////////////
// Dropdowns
//////////////////////////////////////////////////

$dropdown-bg: #fff;

$dropdown-link-color: $gray-900;

$dropdown-link-active-color: $dropdown-link-color;
$dropdown-link-active-bg: $dropdown-bg;

$dropdown-item-padding-y: 0.5rem;

//////////////////////////////////////////////////
// Z-index
//////////////////////////////////////////////////

//////////////////////////////////////////////////
// Navs
//////////////////////////////////////////////////

//////////////////////////////////////////////////
// Navbar
//////////////////////////////////////////////////

$navbar-width-md: 18rem;
$navbar-width-md-collapsed: 4rem;
$navbar-height: 3.5rem;
$navbar-sub-height: 2.5rem;

$navbar-side-width: 20rem;
$navbar-side-width-lg: 25rem;
$navbar-side-width-sm: 15rem;

$navbar-dark-color: #fff;
$navbar-dark-hover-color: #fff;

$nav-collapse-bg: #fff;
$nav-collapse-color: $link-color;

$navbar-shadow-base: 0 3px 6px 0 rgba(#000, 0.16);

$nav-link-padding-x: 0.83rem;
$nav-link-padding-y: 1rem;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: math.div($grid-gutter-width, 2);
$navbar-toggler-border-radius: 0;

//////////////////////////////////////////////////
// Page Hero
//////////////////////////////////////////////////

$page-hero-spacer-top: $grid-gutter-width;
$page-hero-spacer-bottom: $grid-gutter-width;
$page-hero-spacer-top-sm: $grid-gutter-width * 0.5;
$page-hero-spacer-bottom-sm: $grid-gutter-width * 0.5;
$page-hero-spacer-top-lg: $grid-gutter-width * 1.5;
$page-hero-spacer-bottom-lg: $grid-gutter-width * 1.5;

//////////////////////////////////////////////////
// Page Footer
//////////////////////////////////////////////////

$page-footer-bg: $body-bg;
$page-footer-color: #999;

$page-footer-spacer-top: $grid-gutter-width * 2;
$page-footer-spacer-bottom: $grid-gutter-width * 2;
$page-footer-spacer-top-sm: $grid-gutter-width;
$page-footer-spacer-bottom-sm: $grid-gutter-width ;
$page-footer-spacer-top-lg: $grid-gutter-width * 3;
$page-footer-spacer-bottom-lg: $grid-gutter-width * 3;

//////////////////////////////////////////////////
// Cards
//////////////////////////////////////////////////

$card-spacer-x: $grid-gutter-width;
$card-spacer-y: $grid-gutter-width;

$card-border-width: 0;
$card-border-radius: 0;

$card-inner-border-radius: 0;

$card-shadow-base: 0 6px 24px 0 rgba(black, 0.16);
$card-shadow-hover: 0 6px 12px 0 rgba(black, 0.3);
$card-shadow-active: 0 2px 4px 0 rgba(black, 0.3);

//////////////////////////////////////////////////
// Modals
//////////////////////////////////////////////////

$modal-inner-padding: $grid-gutter-width;

//////////////////////////////////////////////////
// Datepicker
//////////////////////////////////////////////////

$datepicker-spacer: $input-btn-padding-x;
$datepicker-prev-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$link-color}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z'/%3E%3C/svg%3E"),'#','%23');
$datepicker-next-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$link-color}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'/%3E%3C/svg%3E"),'#','%23');


//////////////////////////////////////////////////
// Toast
//////////////////////////////////////////////////

$toast-inner-padding: math.div($grid-gutter-width, 2);

$toast-border-radius: $border-radius;

$toast-shadow-base: $card-shadow-base;
$toast-shadow-active: $card-shadow-active;

//////////////////////////////////////////////////
// Pincode
//////////////////////////////////////////////////

$pincode-number-cols: 3;
$pincode-number-size: $grid-gutter-width * 1.5;
$pincode-number-spacer: math.div($grid-gutter-width, 6);

//////////////////////////////////////////////////
// Print
//////////////////////////////////////////////////

$print-page-size: a4;

//////////////////////////////////////////////////
// Project specific variables
// Add your custom variables here
//////////////////////////////////////////////////
